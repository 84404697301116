import {BrowserRouter} from 'react-router-dom';

type Addr = {
    Alias:string,
    OUT: string,
    INDEX: string,
    IDENTITY: string,
    MY: string,
    PRODUCT: string,
}

type GlobalType = {
    BaseUrl: string; // 接口基本访问地址
    ServiceId: string; //服务ID
    LoginPage: string; // 登录页地址
    CheckAuth: boolean; // 确认权限
    IndexPage: string; // 首页地址
    NODE_ENV: string; // 当前环境
    RouterType: any; // 路由类型
    APP: { F1: Addr, F2: Addr }
}

const Constant: GlobalType = require("./.env." + process.env.REACT_APP_ENV + ".json");
Constant.RouterType = BrowserRouter;
Constant.NODE_ENV = process.env.NODE_ENV;
export default Constant;
